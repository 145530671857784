import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { OktaAuth } from '@okta/okta-auth-js';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import config from './config';
import './scss/style.scss';

const oktaAuth = new OktaAuth(config.oidc);

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const TheLayout = React.lazy(() => import('./containers/TheLayout'));

const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));

const App = () => {
  return (
    <Security oktaAuth={oktaAuth}>
        <React.Suspense fallback={loading}>
            <Switch>
              <Route exact={true} path='/404' name="Page 404" render={props => <Page404 {...props}/>} />
              <Route path='/login/callback' component={LoginCallback} />
              <SecureRoute path="/" name="Home" render={props => <TheLayout {...props}/>} />
            </Switch>
        </React.Suspense>
    </Security>
  );
};

export default App;