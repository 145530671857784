export const sygnet = ['512 512', `
  <title>Aquarium Wise</title>
  <path style="fill:#EFEDEE;" d="M352.207,99.027l-44.939,32.634L290.236,256l21.121,123.657l40.851,33.316
	c15.994-2.432,31.476-6.356,46.09-11.692V110.719C383.683,105.384,368.2,101.459,352.207,99.027z"/>
<path style="fill:#D2D2D2;" d="M356.552,392.189c-8.725,1.32-17.598,2.205-26.562,2.665l22.217,18.119
	c15.994-2.432,31.476-6.356,46.09-11.692v-19.02C384.922,386.666,370.935,390.001,356.552,392.189z"/>
<path style="fill:#FA9F6A;" d="M506.618,294.98l-4.078-7.159l7.549-8.303c1.253-7.589,1.911-15.431,1.911-23.517
	c0-69.626-48.226-121.439-113.703-145.281c-24.005,40.888-37.036,91.598-37.036,145.281s13.03,104.403,37.036,145.281
	C450.88,382.134,492.33,344.944,506.618,294.98L506.618,294.98z"/>
<path style="fill:#3A484A;" d="M56.177,325.922C23.577,346.392,0,330.439,0,256s23.577-90.392,56.177-69.923V325.922z"/>
<path style="fill:#E0712F;" d="M95.41,222.735v66.529c-13.827,16.474-27.154,29.065-39.233,36.658
	C46.539,314.947,40.879,292.504,40.879,256s5.662-58.946,15.298-69.923C68.257,193.671,81.583,206.262,95.41,222.735z"/>
<g>
	<path style="fill:#3A484A;" d="M201.55,122.022c-49.177-61.88,17.424-88.992,85.272-65.457L201.55,122.022z"/>
	<path style="fill:#3A484A;" d="M201.55,389.978c-49.177,61.88,17.424,88.992,85.272,65.457L201.55,389.978z"/>
</g>
<path style="fill:#E0712F;" d="M352.207,99.027L201.55,122.021c0,0,19.182-50.618,85.272-65.457
	C309.99,64.608,333.311,78.547,352.207,99.027z"/>
<path style="fill:#FA9F6A;" d="M352.207,412.973L201.55,389.979c0,0,19.182,50.618,85.272,65.457
	C309.99,447.392,333.311,433.453,352.207,412.973z"/>
<path style="fill:#EFEDEE;" d="M201.56,122.022h-0.01c-0.003,0.006-0.005,0.012-0.007,0.019l-32.579,34.148L145.117,266.08
	l23.846,89.049l32.587,34.849c20.368,9.995,41.594,16.944,63.004,21.093V100.929C243.154,105.077,221.917,112.027,201.56,122.022z"
	/>
<path style="fill:#D2D2D2;" d="M263.382,390.168h-0.011c-22.76-4.41-45.325-11.799-66.977-22.424
	c-9.669-4.736-19.151-10.115-28.379-16.158l0.949,3.544l32.587,34.849l0,0c5.092,2.499,10.238,4.807,15.426,6.929
	c15.566,6.365,31.52,11.053,47.578,14.164v-20.689C264.163,390.309,263.773,390.244,263.382,390.168z"/>
<g>
	<path style="fill:#FA9F6A;" d="M315.273,256c0,56.912,12.938,111.301,36.933,156.973c-28.236,4.272-58.088,3.853-87.643-1.901
		h-0.01C236.685,368.65,221.437,314.057,221.437,256s15.248-112.64,43.116-155.072h0.01c29.555-5.754,59.406-6.173,87.643-1.901
		C328.222,144.699,315.273,199.088,315.273,256z"/>
	<path style="fill:#FA9F6A;" d="M175.449,256c0,47.634,9.065,93.508,26.101,133.978c-44.844-21.961-85.436-58.609-114.071-112.466
		c-7.133-13.428-7.133-29.596,0-43.024c28.635-53.857,69.217-90.505,114.071-112.466C184.524,162.491,175.449,208.356,175.449,256z"
		/>
</g>
<g>
	<path style="fill:#E0712F;" d="M404.606,398.876c21.035-8.391,40.918-20.057,57.843-35.164c0.076-0.068,0.156-0.135,0.232-0.204
		c7.745-6.938,14.858-14.601,21.098-22.922c0.063-0.083,0.124-0.168,0.186-0.251c7.926-10.609,14.385-22.193,19.095-34.668
		c-21.516,33.628-56.287,59.082-97.511,74.093c-5.23,1.909-10.573,3.633-15.997,5.199c2.73,5.594,5.644,11.041,8.745,16.322
		C400.412,400.512,402.514,399.709,404.606,398.876z"/>
	<path style="fill:#E0712F;" d="M263.371,390.168c-4.14-0.802-8.274-1.706-12.397-2.707c4.125,8.217,8.65,16.108,13.59,23.611
		c25.304,4.926,51.35,6.049,76.982,3.281c3.576-0.386,7.131-0.847,10.66-1.38c-3.263-6.21-6.314-12.588-9.163-19.105
		C317.096,396.457,290.127,395.374,263.371,390.168z"/>
	<path style="fill:#E0712F;" d="M201.55,389.978c-3.308-7.857-6.305-15.922-9.004-24.16
		c-42.473-21.668-81.137-55.926-110.119-104.686c0.057,0.505,0.132,1.009,0.205,1.512c0.756,5.19,2.386,10.234,4.847,14.868
		c7.73,14.539,16.672,28.447,26.827,41.414C139.284,350.822,169.318,374.194,201.55,389.978z"/>
</g>
<g>
	<path style="fill:#3A484A;" d="M507.818,279.418c-0.118,0.013-11.979,1.202-24.453-2.232c-4.114-1.133-8.367,1.285-9.499,5.399
		c-1.133,4.114,1.285,8.367,5.399,9.499c8.951,2.464,17.576,2.989,23.298,2.989c1.603,0,2.966-0.041,4.057-0.093
		c1.437-5.026,2.599-10.182,3.471-15.463C509.359,279.376,508.596,279.337,507.818,279.418z"/>
	<path style="fill:#3A484A;" d="M435.536,273.809c-1.064,0-2.145-0.22-3.176-0.687c-3.889-1.757-5.617-6.334-3.86-10.222
		c3.365-7.446,9.921-13.117,17.988-15.56c8.068-2.444,16.669-1.361,23.599,2.969c3.619,2.261,4.719,7.028,2.458,10.646
		c-2.261,3.618-7.027,4.72-10.646,2.458c-3.147-1.967-7.132-2.436-10.932-1.284c-3.8,1.151-6.856,3.75-8.385,7.133
		C441.291,272.118,438.479,273.809,435.536,273.809z"/>
</g>
`]
