const OKTA_CLIENT_ID = process.env.REACT_APP_CLIENT_ID || '0oa1c9h8rpOg5Y9fp5d6';
const OKTA_ISSUER = process.env.REACT_APP_ISSUER || 'https://auth.aquariumwise.app/oauth2/default';
const OKTA_REDIRECT_URI = process.env.REACT_APP_OKTA_REDIRECT_URI || 'http://localhost:3000/login/callback';
const OKTA_TESTING_DISABLEHTTPSCHECK = process.env.REACT_APP_OKTA_TESTING_DISABLEHTTPSCHECK || false;

export default {
  oidc: {
    clientId: OKTA_CLIENT_ID,
    issuer: OKTA_ISSUER,
    redirectUri: OKTA_REDIRECT_URI,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
  },
  resourceServer: {
    messagesUrl: 'http://localhost:3000/api/messages',
  },
};